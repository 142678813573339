@import '../../../../scss/theme-bootstrap';

.sticky-footer-chat {
  display: block;
  margin-top: 2px;
  #{$ldirection}: auto;
  #{$rdirection}: 15px;
  position: fixed;
  top: 77%;
  width: auto;
  z-index: 100;
  @include breakpoint($medium-up) {
    margin-top: 0;
    #{$rdirection}: 40px;
    top: 88%;
  }
  a {
    @include border-radius(50%);
    @include transition(opacity 0.4s ease-in-out, border 0.2s ease-in-out, color 0.2s ease-in-out);
    background-image: url('https://www.bobbibrowncosmetics.ca/media/images/global/bb-sticky-circle.png');
    background-repeat: no-repeat;
    background-size: 44px 44px;
    background-position: center;
    width: 44px;
    height: 44px;
    opacity: 1;
    pointer-events: auto;
    position: fixed;
    #{$ldirection}: 15px;
    text-align: center;
    top: 75%;
    z-index: 100;
    @include breakpoint($medium-up) {
      #{$ldirection}: auto;
      #{$rdirection}: 40px;
    }
  }
  img {
    height: 21px;
    #{$ldirection}: 0;
    position: relative;
    top: 11px;
    width: 15px;
  }
  &.sticky-footer-new-chat {
    .site-footer__wrap & {
      a {
        background: none;
        #{$rdirection}: 15px;
        bottom: 120px;
        top: auto;
        #{$ldirection}: auto;
        @include breakpoint($medium-up) {
          width: 62px;
          height: 62px;
          bottom: 60px;
          #{$rdirection}: 40px;
        }
      }
      img {
        height: auto;
        width: auto;
      }
    }
    @if $cr22 == true {
      .wrap.site-footer__wrap & {
        a {
          bottom: 118px;
        }
      }
    }
  }
}
